@import '~bootstrap/scss/bootstrap';
$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';
@import '~@fortawesome/fontawesome-free-webfonts/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free-webfonts/scss/fa-regular.scss';
// @import '~@fortawesome/fontawesome-free-webfonts/scss/fa-solid.scss';
// @import '~@fortawesome/fontawesome-free-webfonts/scss/fa-brands.scss';

$roboto-font-path: 'assets/font/roboto/';
$image-path: '~mdb-admin-pro/img/';
@import '~mdb-admin-pro/scss/mdb';

// your styles here

// btn group

.btn-group-container {
  padding-top: 4px;
}

.btn-group-container > label {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
  color: #757575;
  transform: scale(0.8) translateY(10px);
  margin-bottom: 0px;
}

.btn-group-toggle ~ .invalid-feedback {
  display: block;
  margin-top: 12px;
}

pdf-viewer iframe {
  width: 100%;
  height: 100%;
}
